<template>
  <div>
    <a
      class="text-decoration-none text-subtitle-2 font-weight-bold"
      @click="returnBack"
    >
      <v-icon
        color="primary"
        dense
        class="mr-2"
      >
        {{ icons.mdiArrowLeft }}
      </v-icon>
      Kembali
    </a>
    <h3 class="my-8">
      Edit Materi
    </h3>
    <v-card class="pa-4">
      <v-card-text>
        <div class="mb-4">
          <h3>Edit Materi (Wajib diisi)</h3>
          <span>Materi akan ditambahkan ke dalam BAB</span>
        </div>
        <div>
          <v-text-field
            v-model="chapter.chapter_of"
            label="Materi ke"
            :rules="[validation.required]"
            outlined
            dense
            type="number"
          ></v-text-field>
        </div>
        <div>
          <v-text-field
            v-model="chapter.name"
            label="Judul Materi"
            :rules="[validation.required]"
            outlined
            dense
          ></v-text-field>
        </div>
        <div class="mb-4">
          <h3>Icon (Optional)</h3>
          <span>Icon untuk materi pelajaran</span>
        </div>
        <div class="d-flex mb-4">
          <v-card
            width="120"
            height="110"
            class="me-4 d-flex align-center justify-center"
            style="border: 1px dashed #959595;"
            @click="addIcon"
          >
            <div class="text-center">
              <v-icon
                v-if="!lesson.icon"
                large
              >
                {{ icons.mdiPlus }}
              </v-icon>
              <v-img
                v-else
                v-model="chapter.bank_icon_uuid"
                width="40"
                :src="lesson.icon"
                class="mx-auto"
              ></v-img>
            </div>
          </v-card>
          <div>
            <h4 class="font-weight-light">
              Icon akan ditampilkan di halaman materi pelajaran
            </h4>
            <h4 class="primary--text mb-10">
              Pilih Icon
            </h4>
            <small>*Icon akan dipilih secara default jika anda ingin melewatkannya</small>
          </div>
        </div>
        <h3>Isi materi (Wajib diisi)</h3>
        <p>Materi bisa berupa teks atau video</p>
        <div>
          <v-text-field
            v-model="lesson.name"
            label="Nama Materi"
            :rules="[validation.required]"
            outlined
            dense
          >
          </v-text-field>
        </div>
        <div>
          <v-text-field
            v-model="lesson.url_video"
            label="Link Video Materi (Jika Ada)"
            outlined
            dense
          >
          </v-text-field>
        </div>
        <div class="my-4">
          <CKEditor
            v-model="lesson.content"
            :value="initialContent"
            label="Content"
            outlined
            dense
            @input="handleCKeditor"
          ></CKEditor>
        </div>

        <div>
          <h3>Komentar</h3>
          <v-switch
            v-model="status"
            :label="`Komentar ${status?'Diaktifkan': 'Dinonaktifkan'} `"
          ></v-switch>
        </div>
        <v-divider></v-divider>
      </v-card-text>
      <v-card-actions class="d-flex justify-center">
        <v-btn
          color="primary"
          large
          :disabled="!formValidLesson || !formValidChapter"
          :loading="isLoadingButton"
          @click="update()"
        >
          Update
        </v-btn>

        <v-btn
          color="primary"
          outlined
          large
          @click="returnBack"
        >
          Batal
        </v-btn>
      </v-card-actions>
    </v-card>
    <ModalDialog
      :visible="modalDialog"
      :width="width"
      :hidden-actions="false"
      header="Pilih Icon"
      @close-modal="handlerButton"
    >
      <template v-slot:body>
        <h4>Icon akan ditampilkan di halaman materi pelajaran</h4>
        <p>Pilih salah satu icon:</p>
        <v-row>
          <v-col
            v-for="featureItem in features"
            :key="featureItem.uuid"
            cols="3"
          >
            <v-card
              v-model="lesson.icon"
              height="80"
              outlined
              class="pa-4 elevation-0 d-flex justify-center align-center"
              clickable
              :color="cardActive == featureItem.uuid ? 'primary' : ''"
              @click="getIcon(featureItem.icon, featureItem.uuid)"
            >
              <div>
                <v-img
                  width="40"
                  :src="featureItem.icon"
                  class="mx-auto"
                ></v-img>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </template>
      <template v-slot:footer>
        <v-btn
          color="primary"
          dense
          large
          @click="chooseIcon(icon.icon, icon.uuid)"
        >
          Pilih
        </v-btn>
        <v-btn
          color="primary"
          dense
          large
          outlined
          @click="handlerButton"
        >
          Batal
        </v-btn>
      </template>
    </ModalDialog>
  </div>
</template>

<script>
import { integerValidator, required } from '@core/utils/validation'
import CKEditor from '../../components/CkEditor.vue'
import ModalDialog from '../../components/ModalDialog.vue'

import { mdiArrowLeft, mdiPlus } from '@mdi/js'

export default {
  name: 'Lesson',
  components: {
    ModalDialog,
    CKEditor,
  },
  data() {
    return {
      icons: {
        mdiArrowLeft,
        mdiPlus,
      },
      chapter: {
        section_uuid: '',
        chapter_of: '',
        name: '',
        type: 'lesson',
        bank_icon_uuid: null,
      },
      validation: {
        required,
        integerValidator,
      },
      subject: {},
      chapters: [],
      lesson: {
        uuid: '',
        chapter_uuid: '',
        name: '',
        content: '',
        url_video: '',
        is_comment: 0,
        icon: '',
        teacher_uuid: '',
      },
      icon: {
        uuid: '',
        icon: '',
      },
      features: [],
      isLoadingButton: false,
      isDisabledButton: false,
      modalDialog: false,
      width: 500,
      status: true,
      tempContent: '',
      tempImage: [],
      cardActive: '',
      chapterUuid: '',
      formValidLesson: false,
      formValidChapter: false,
    }
  },
  computed: {
    initialContent: {
      get() {
        if (this.chapter.details.content === null) {
          return ''
        }

        return this.chapter.details.content
      },
      set() {
        return this.chapter.details.content
      },
    },
  },
  watch: {
    lesson: {
      handler() {
        const valid = []
        const requiredField = ['name', 'content']
        Object.entries(this.lesson).forEach(([key, item]) => {
          if (requiredField.includes(key)) valid.push(!!(item && item.length))
        })
        this.formValidLesson = !valid.includes(false)
      },
      deep: true,
    },
    chapter: {
      handler() {
        const valid = []
        const requiredField = ['name', 'chapter_of', 'bank_icon_uuid']
        Object.entries(this.chapter).forEach(([key, item]) => {
          if (requiredField.includes(key)) valid.push(!!(item && item.length))
        })
        this.formValidChapter = !valid.includes(false)
      },
      deep: true,
    },
  },
  async mounted() {
    await this.getTeacher()
    await this.getBankIcon()
    await this.getChapter(this.$route.params.uuid)
  },

  methods: {
    getTeacher() {
      this.teacher = JSON.parse(localStorage.getItem('user')).user.details
      this.teacher.forEach(el => {
        if (el.teacher.uuid === undefined) {
          this.lesson.teacher_uuid = el.teacher.uuid

          return
        }
        if (el.teacher.uuid === null) {
          this.showSnackbar({
            text: "You don't have access to teacher",
            color: 'error',
          })

          return
        }
        this.lesson.teacher_uuid = el.teacher.uuid
      })

      return true
    },
    // cancelAction() {
    //   this.modalDialog = false
    //   const image = this.tempContent.split('"')
    //   image.forEach(async item => {
    //     const img = item.split('/').pop()
    //     if (img.length === 44) {
    //       const fileName = {
    //         file: img,
    //       }
    //       await this.deleteStorage(fileName)
    //     }
    //   })
    //   this.tempContent = ''

    //   return this.$router.back()
    // },
    async deleteStorage(fileName) {
      await this.$services.ApiServices.deleteStorage('news', fileName).then(({ data }) => true)
    },
    handleCKeditor(event) {
      this.chapter.details.content = event
      if (this.tempContent.length < event.length) {
        this.tempContent = event
      }
    },
    addIcon() {
      this.modalDialog = true
    },
    getIcon(icon, uuid) {
      this.icon.icon = icon
      this.icon.uuid = uuid
      this.cardActive = uuid
    },
    chooseIcon(icon, uuid) {
      this.lesson.icon = icon
      this.chapter.bank_icon_uuid = uuid
      this.modalDialog = false
    },
    async getBankIcon() {
      await this.$services.ApiServices.list('bankicon').then(
        ({ data }) => {
          this.features = data.data
        },
        err => {
          console.error(err)
        },
      )
    },
    returnBack() {
      return this.$router.back()
    },
    async getChapter(uuid) {
      this.tempImage = []

      this.$services.ApiServices.get('chapter', uuid).then(
        ({ data }) => {
          this.chapter = { ...data.data }
          this.lesson = data.data.details
          this.chapter.bank_icon_uuid = data.data.icon.uuid
          this.lesson.uuid = data.data.details.uuid
          this.lesson.icon = data.data.icon.icon
          this.cardActive = this.chapter.bank_icon_uuid

          const image = data.data.details.content.split('"')
          image.forEach(async item => {
            const img = item.split('/').pop()
            if (img.length === 44) {
              this.tempImage.push(img)
            }
          })
          this.status = data.data.details.is_comment === 'Allowed'
        },
        err => console.error(err),
      )
    },
    async update() {
      this.isLoadingButton = true
      this.isDisabledButton = true
      // this.chapter.section_uuid = this.$route.params.uuid
      this.chapterUuid = ''
      const image = this.chapter.details.content.split('"')
      image.forEach(item => {
        const img = item.split('/').pop()
        if (img.length === 44) {
          const carIndex = this.tempImage.indexOf(img)
          this.tempImage.splice(carIndex, 1)

          this.tempImage.forEach(async item => {
            await this.deleteStorage({ file: item })
          })
        }
      })
      await this.$services.ApiServices.update('chapter', this.chapter, this.chapter.uuid).then(
        ({ data }) => {
          this.showSnackbar({
            text: data.result.message,
            color: 'success',
          })
          this.chapterUuid = data.data.uuid
        },
        err => {
          this.isLoadingButton = false
          this.isDisabledButton = false
          console.error(err)
        },
      )
      this.lesson.chapter_uuid = this.chapterUuid
      this.lesson.is_comment = this.status ? 1 : 0
      this.lesson.teacher_uuid = this.teacher_uuid
      this.lesson.name = this.chapter.name
      this.lesson.icon = this.chapter.bank_icon_uuid
      await this.$services.ApiServices.update('lesson', this.lesson, this.lesson.uuid).then(({ data }) => {
        this.lesson = data.data
      })
      this.isLoadingButton = false
      this.isDisabledButton = false
      this.returnBack()
    },
    showSnackbar(snackbarData) {
      this.$store.dispatch('snackbar/showSnackbar', snackbarData)
    },
    handlerButton() {
      this.lesson.icon = null
      this.modalDialog = false
      this.getChapter(this.$route.params.uuid)
      this.cardActive = ''
    },
  },
}
</script>

<style>
.v-application--is-ltr .v-input--selection-controls__input {
  margin-right: 15px !important;
}
.clickable {
  cursor: pointer;
}
</style>
